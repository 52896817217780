import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { IPagination, IPaginationAlt } from '../../models/pagination.model';
import { MatSort, Sort } from '@angular/material/sort';
import { MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { IPageable } from '../../models/pageable.model';
import { PageableHelperService } from '../../services/helpers/pageable-helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss'],
})
export class TableWrapperComponent implements OnChanges {
  @Input() tableConfig: IPagination<any> | IPaginationAlt<any>;

  @Input() sort: MatSort;
  @Input() filterPredicate?: MatTableDataSource<any>['filterPredicate'];
  @Input() pageable: IPageable = this.pageableHelper.getPageable();
  @Input() isPageable: boolean = true;
  @Output() pageableChange: EventEmitter<IPageable> = new EventEmitter<IPageable>();

  @Output() getData: EventEmitter<any> = new EventEmitter<any>();

  @Input() isUrlChangeable: boolean = false;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef> =
    new QueryList<MatColumnDef>();

  public dataSource: MatTableDataSource<any>;
  public totalElements: number;
  public displayedColumns: string[] = [];
  private isInitialSetupDone: boolean = false;
  public rowCSS: any;

  constructor(
    private router: Router,
    private location: Location,
    private pageableHelper: PageableHelperService,
    public translate: TranslateService,
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }

  ngOnChanges(): void {
    if (this.tableConfig) {
      this.loadDataTable();
    }
  }

  public loadDataTable(): void {
    if (this.isUrlChangeable) {
      this.location.go(this.pageableHelper.getPageableUrl(this.pageable, this.router));
    }

    this.totalElements = this.tableConfig.totalElements;
    this.displayedColumns = [];
    this.columnDefs.forEach((column) => {
      this.displayedColumns.push(column.name);
      this.table.addColumnDef(column);
    });

    this.dataSource = new MatTableDataSource<any>(this.tableConfig.items || this.tableConfig.content);
    if (this.filterPredicate) {
      this.dataSource.filterPredicate = this.filterPredicate;
      this.dataSource.filter = 'update';
    }
    if (this.sort && !this.isInitialSetupDone) {
      this.loadSort();
    }
  }

  private loadSort(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sort.sortChange.subscribe((sortChange: Sort) => {
      this.sortData(sortChange);
    });
    this.isInitialSetupDone = true;
  }

  public nextPage(event: PageEvent): void {
    this.pageable.page = event.pageIndex;
    this.pageableChange.emit(this.pageable);
    this.getData.emit();
  }

  public sortData(sort: Sort): void {
    this.pageable.field = sort.active;
    this.pageable.order = sort.direction;
    this.pageable.page = 0;
    this.pageableChange.emit(this.pageable);

    this.getData.emit();
  }
}
