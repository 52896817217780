<div class="table-responsive">
  <table mat-table [dataSource]="dataSource">
    <ng-content></ng-content>

    <ng-container matColumnDef="noData">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        {{ 'SHARED.COMPONENTS.TABLE_WRAPPER.NO_RECORDS_FOUND' | translate }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        selected: this.tableConfig?.rowCss && this.tableConfig?.rowCss(row)
      }"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="['noData']"
      [hidden]="dataSource && dataSource.data.length > 0"
    ></tr>
  </table>
</div>

<mat-paginator
  *ngIf="isPageable"
  showFirstLastButtons
  [pageSizeOptions]="[pageable.size]"
  [pageSize]="pageable.size"
  [length]="totalElements"
  [pageIndex]="pageable.page"
  (page)="nextPage($event)"
></mat-paginator>
