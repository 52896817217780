import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import {
  Desk,
  DeskCreateRequest,
  DeskUpdateRequest,
  Floor,
  FloorCreateRequest,
  FloorEditRequest,
} from '../models';
import { AlertService, AlertType } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class OfficeManagementService {
  // injects
  private readonly alertService = inject(AlertService);
  private readonly httpClient = inject(HttpClient);
  private readonly translate = inject(TranslateService);

  // api urls
  private readonly urlBase: string = 'api/office-management';
  private readonly urlDesks: string = `${this.urlBase}/desk`;
  private readonly urlFloors: string = `${this.urlBase}/floor`;

  // signals
  desks = signal<Desk[]>([]);
  floors = signal<Floor[]>([]);

  constructor() {}

  createDesk(payload: DeskCreateRequest): Observable<Desk[]> {
    return this.httpClient
      .post<Desk[]>(`${this.urlDesks}/create`, payload)
      .pipe(catchError((error) => this.handleError(error)));
  }

  createFloor({ floorName, image }: FloorCreateRequest): Observable<Floor> {
    const formData = new FormData();

    formData.append('floorName', floorName);
    formData.append('image', image);

    return this.httpClient.post<Floor>(`${this.urlFloors}/create`, formData).pipe(
      tap(({ name }) =>
        this.handleSuccess(
          `${this.translate.instant('ADMIN.OFFICE_SPACE.CREATE_FLOOR_SUCCESS')} '${name}'`,
        ),
      ),
      catchError((error) => this.handleError(error)),
    );
  }

  getAllFloors() {
    this.httpClient
      .get<Floor[]>(`${this.urlFloors}/all`)
      .pipe(catchError((error) => this.handleError(error)))
      .subscribe((response) => this.floors.set(response));
  }

  getFloorDesks(floorId: number) {
    this.httpClient
      .get<Desk[]>(`${this.urlDesks}/list/floor/${floorId}`)
      .pipe(catchError((error) => this.handleError(error)))
      .subscribe((response) => this.desks.set(response));
  }

  getFloorImage(floorId: number): Observable<Blob> {
    return this.httpClient
      .get(`${this.urlFloors}/${floorId}/image`, {
        responseType: 'blob',
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  removeDesk(deskId: number): Observable<any> {
    return this.httpClient.delete<Floor>(`${this.urlDesks}/${deskId}`).pipe(
      tap(() =>
        this.handleSuccess(
          `${this.translate.instant('ADMIN.OFFICE_SPACE.REMOVE_DESK_SUCCESS')}`,
        ),
      ),
      catchError((error) => this.handleError(error)),
    );
  }

  removeFloor(floorId: number): Observable<any> {
    return this.httpClient.delete<Floor>(`${this.urlFloors}/${floorId}`).pipe(
      tap(({ name }) =>
        this.handleSuccess(
          `${this.translate.instant('ADMIN.OFFICE_SPACE.REMOVE_FLOOR_SUCCESS')} '${name}'`,
        ),
      ),
      catchError((error) => this.handleError(error)),
    );
  }

  updateDesk(payload: DeskUpdateRequest): Observable<Desk> {
    return this.httpClient
      .put<Desk>(`${this.urlDesks}/update`, [payload])
      .pipe(catchError((error) => this.handleError(error)));
  }

  updateFloor({ active, id, image, name, primary }: FloorEditRequest): Observable<Floor> {
    const formData = new FormData();

    formData.append('active', String(active));
    formData.append('id', String(id));
    formData.append('name', name);
    formData.append('primary', String(primary));

    if (image) {
      formData.append('image', image);
    }

    return this.httpClient.put<Floor>(`${this.urlFloors}/update`, formData).pipe(
      tap(({ name }) =>
        this.handleSuccess(
          `${this.translate.instant('ADMIN.OFFICE_SPACE.UPDATE_FLOOR_SUCCESS')} '${name}'`,
        ),
      ),
      catchError((error) => this.handleError(error)),
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.alertService.showAlert(error.error, AlertType.error);
    return throwError(() => error);
  }

  private handleSuccess(message: string) {
    this.alertService.showAlert(message, AlertType.success);
  }
}
