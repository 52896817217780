import moment from 'moment';
import { Provider, forwardRef, Type } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export const keepObjKeysOrder = (): number => {
  return 0;
};

export const reduceVacationDaysToNumber = (
  vacationDays: { morning: boolean; afternoon: boolean }[],
) => {
  return vacationDays.reduce((days, { morning, afternoon }) => {
    if (morning) days += 0.5;
    if (afternoon) days += 0.5;

    return days;
  }, 0);
};

export const getDateRange = (firstDate: Date, lastDate: Date) => {
  if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day')) {
    return [moment(lastDate).format('YYYY-MM-DD')];
  }

  let date = moment(firstDate).format('YYYY-MM-DD');
  const dates = [date];

  while (moment(date).isBefore(lastDate)) {
    date = moment(date).add(1, 'day').format('YYYY-MM-DD');
    dates.push(date);
  }

  return dates;
};

export function sanitizeQueryParams(record: Record<string, unknown>, timestamp?: unknown) {
  const result: Record<string, unknown> = {};

  for (const key of Object.keys(record)) {
    result[key] = record[key] || undefined;
  }

  result.t = timestamp;

  return result;
}

export function errorMessage(payload: any, fallback?: string) {
  return (
    payload?.error?.error || payload?.error || payload?.data?.error || fallback || 'Unknown error'
  );
}

export function provideValueAccessor(component: Type<any>): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true,
  };
}
